import { useEffect, useContext, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@/hooks/redux';
import { LoaderDots, NoData, Modal } from '@/components';
import DosPlayer from '@/components/DosPlayer/DosPlayer';

import { LAB_TYPE, STATUS_LAB } from '@/constants';
import {
    getLabThank,
    getWsLabByUuidThunk,
} from '@/store/reducers/LabSlice/AsyncThanks';
import {
    getSchemeThank,
    getSchemeWsThunk,
} from '@/store/reducers/SchemeSlice/AsyncThanks';
import {
    getTemplateByIdThank,
    runTemplateThank,
} from '@/store/reducers/TemplatesSlice/AsyncThanks';
import {
    getTasksThunk,
    getTasksWsThunk,
} from '@/store/reducers/TasksSlice/AsyncThunks';
import {
    getFlagsThunk,
    getFlagsWsThunk,
} from '@/store/reducers/FlagsSlice/AsyncThunks';
import { labWithSchemeSlice } from '@/store/reducers/LabSlice';
import { Header, Sidebar, HelpBar } from './components';
import { StyledLab, StyledContainer } from './styled';
import { useStatusPolingRequest } from './hooks/useStatusPolingRequest';
import { StyledPageLoaderContainer } from '@/pages/styled';
import { LabContext } from './context';
import { IProps } from './components/Actions/Actions';
import { DosPlayer as Instance } from 'js-dos';
import React from 'react';

const DoomModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Contols: WASD,arrows,ctrl,space,mouse,numbers"
        >
            <DosPlayer bundleUrl="https://cdn.dos.zone/custom/dos/doom.jsdos"></DosPlayer>
        </Modal>
    );
};

export const Lab = () => {
    const { type } = useContext(LabContext);
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const { template_id, uuid } = useParams();
    const { lab, labState } = useAppSelector(
        (state) => state.LabWithSchemeSlice
    );
    const { profile } = useAppSelector((state) => state.ProfileSlice);
    const { tasks } = useAppSelector((state) => state.TaskSlice);
    const { currentTemplate, currentTemplateState } = useAppSelector(
        (state) => state.TemplatesSlice
    );
    const iCanRun = lab?.you_can_run.can_run;
    const [isDoomOpen, setIsDoomOpen] = useState(false);

    useEffect(() => {
        if (lab?.uuid) {
            switch (type) {
                case LAB_TYPE.DEFAULT:
                    dispatch(getFlagsThunk({ uuid: lab.uuid }));
                    dispatch(getSchemeThank({ uuid: lab.uuid }));
                    dispatch(getTasksThunk(lab.uuid));
                    return;
                case LAB_TYPE.WORKSHOP:
                    dispatch(getFlagsWsThunk(lab.uuid));
                    dispatch(getSchemeWsThunk(lab.uuid));
                    dispatch(getTasksWsThunk(lab.uuid));
            }
        }
    }, [lab, dispatch, type]);

    useEffect(() => {
        switch (type) {
            case LAB_TYPE.DEFAULT:
                dispatch(getTemplateByIdThank(Number(template_id)));
                dispatch(getLabThank({ templateId: Number(template_id) }));
                return;
            case LAB_TYPE.WORKSHOP:
                dispatch(getWsLabByUuidThunk(uuid || 'nouuid'));
        }
        return () => {
            dispatch(labWithSchemeSlice.actions.resetLab());
        };
    }, [template_id, type, dispatch, uuid]);

    useStatusPolingRequest({
        lab,
        template: currentTemplate,
        tasks,
    });

    const renderContentPage = () => {
        const isFetching = labState.fetching || currentTemplateState.fetching;
        const isEmptyLab = !isFetching && !lab && currentTemplate?.you_can_run;
        if (isFetching) {
            return (
                <StyledPageLoaderContainer style={{ minHeight: '100vh' }}>
                    <LoaderDots />
                </StyledPageLoaderContainer>
            );
        }
        if (isEmptyLab) {
            return (
                <NoData
                    title="Run the lab for the first time"
                    buttons={[
                        {
                            title: 'run the lab',
                            action() {
                                if (currentTemplate)
                                    dispatch(
                                        runTemplateThank(currentTemplate.id)
                                    );
                            },
                        },
                    ]}
                />
            );
        }
        if (
            !labState.fetching &&
            !currentTemplateState.fetching &&
            !lab &&
            !currentTemplate?.you_can_run &&
            currentTemplate?.is_premium &&
            !profile?.is_premium
        ) {
            return (
                <NoData
                    title="The laboratory is unavailable."
                    text="This lab are available for premium users only"
                    buttons={[
                        {
                            title: 'Subscribe',
                            action() {
                                window.open(
                                    process.env.REACT_APP_PAYMENT_URL,
                                    '_blank'
                                );
                            },
                        },
                    ]}
                />
            );
        }
        if (
            !labState.fetching &&
            !currentTemplateState.fetching &&
            !lab &&
            !currentTemplate?.you_can_run
        ) {
            return <NoData title="You already have another active lab" />;
        }
        if (
            lab &&
            (lab.status === STATUS_LAB.SET_UP_VM ||
                lab.status === STATUS_LAB.DEPLOY)
        ) {
            const avgTime = lab.recreated
                ? lab.average_deployment_time_no_siem?.replace(/^00:/, '')
                : lab.average_deployment_time_siem?.replace(/^00:/, '');

            return (
                <>
                    <Header
                        disabled={lab?.you_can_run.description}
                        type={type}
                        fetchingTabs={labState.fetching}
                        fetchingTitle={currentTemplateState.fetching}
                        pathname={pathname}
                        iCanRun={iCanRun}
                        lab={lab}
                        currentTemplate={currentTemplate}
                    />
                    <StyledContainer>
                        <Sidebar>
                            <HelpBar
                                messageFetching={labState.fetching}
                                message={lab?.description ?? undefined}
                                firstBlood={lab?.first_blood}
                                lastSolved={lab?.last_solved}
                            />
                            <NoData
                                title={`The lab is starting now—average wait: ${avgTime}. Timer’s above. We know waiting isn’t fun, so `}
                                buttons={[
                                    {
                                        title: 'Play DOOM while you wait',
                                        action() {
                                            setIsDoomOpen(true);
                                        },
                                    },
                                ]}
                            />
                            <DoomModal
                                isOpen={isDoomOpen}
                                onClose={() => {
                                    console.log('Closing DOOM modal');
                                    setIsDoomOpen(false);
                                }}
                            />
                        </Sidebar>
                    </StyledContainer>
                </>
            );
        }

        return (
            <>
                <Header
                    disabled={lab?.you_can_run.description}
                    type={type}
                    fetchingTabs={labState.fetching}
                    fetchingTitle={currentTemplateState.fetching}
                    pathname={pathname}
                    iCanRun={iCanRun}
                    lab={lab}
                    currentTemplate={currentTemplate}
                />
                <StyledContainer>
                    <Sidebar>
                        <HelpBar
                            messageFetching={labState.fetching}
                            message={lab?.description ?? undefined}
                            firstBlood={lab?.first_blood}
                            lastSolved={lab?.last_solved}
                        />
                        <>{lab && <Outlet />}</>
                    </Sidebar>
                </StyledContainer>
            </>
        );
    };

    return (
        <StyledLab
            style={
                type === LAB_TYPE.WORKSHOP
                    ? { minWidth: '1280px', overflow: 'auto' }
                    : undefined
            }
        >
            {renderContentPage()}
        </StyledLab>
    );
};
