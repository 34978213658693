import { forwardRef } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import {
    StyledPageContent,
    StyledMainTitleContainer,
    StyledMainTitle,
    StyledMainSubtitle,
    StyledSteps,
    StyledMainButton,
} from './styled';
import { Step } from '../../components/Step/Step';
import { Button } from '../../components/Button/Button';
import { steps } from '../../constants';
import main_title_v1 from '../../assets/SVGTitles/main_titles_v_1.svg';

export const Main = forwardRef(
    (_, ref: React.ForwardedRef<HTMLInputElement>) => {
        const navigate = useNavigate();

        return (
            <StyledPageContent ref={ref} id="main">
                <StyledMainTitleContainer>
                    <StyledMainSubtitle>Boost Your</StyledMainSubtitle>
                    <StyledMainTitle src={main_title_v1} alt="cybersec" />

                    <StyledMainSubtitle>skills</StyledMainSubtitle>
                </StyledMainTitleContainer>
                <StyledSteps>
                    {steps.map((s) => (
                        <Step text={s.text} step={s.step} key={nanoid()} />
                    ))}
                </StyledSteps>
                <StyledMainButton>
                    <Button
                        onClick={() => navigate('/auth/signup')}
                        color="primary"
                    >
                        Try Defbox
                    </Button>
                </StyledMainButton>
            </StyledPageContent>
        );
    }
);
