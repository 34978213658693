import { useEffect, useState } from 'react';
import { Input } from '../Input/Input/Input';
import { DropdownMenu } from '../Dropdown/DropdownMenu/DropdownMenu';
import { IDropdownMenuItem } from '../Dropdown/types';
import { SelectProps } from './types';
import { ShevronDown, ShevronUp } from '../Icons/index';

export const Select = (props: SelectProps) => {
    const {
        options: inputOptions,
        label,
        description,
        onChange,
        defaultValue,
        style,
    } = props;
    const [options, setOptions] = useState<IDropdownMenuItem[]>(inputOptions);
    const [isOpen, setIsOpen] = useState(false);

    const getIDs = (opt: IDropdownMenuItem[]) => {
        return [...opt].map((i) => i.id);
    };

    const [IDs, setIDs] = useState<(string | number)[]>(getIDs(inputOptions));

    useEffect(() => {
        setOptions(inputOptions);
        setIDs(getIDs(inputOptions));
    }, [inputOptions, setOptions, setIDs]);

    const [value, setValue] = useState<IDropdownMenuItem | undefined>(
        options[IDs.indexOf(defaultValue!)]
    );

    useEffect(() => {
        if (defaultValue && IDs && options && setValue) {
            setValue(options[IDs.indexOf(defaultValue)]);
        }
    }, [setValue, defaultValue, IDs, options]);

    const onChangeHandler = (id: string | number) => {
        if (IDs.indexOf(id) !== -1) {
            setValue(options[IDs.indexOf(id)]);
            if (onChange) onChange(options[IDs.indexOf(id)]);
        }
    };

    return (
        <DropdownMenu
            style={style}
            isOpen={isOpen}
            onClose={(e) => {
                if (isOpen) {
                    setIsOpen(false);
                }
            }}
            toggleOpen={() => {
                setIsOpen(!isOpen);
            }}
            list={options}
            matchWidth={true}
            onClick={onChangeHandler}
            offset={description ? -20 : 4}
            autocomplete
        >
            <Input
                style={style}
                cursor="pointer"
                value={value ? value!.title : undefined}
                type="text"
                readOnly="readonly"
                iconRight={isOpen ? <ShevronUp /> : <ShevronDown />}
                onClickIconRight={(e) => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                }}
                label={label}
                description={description}
            />
        </DropdownMenu>
    );
};
