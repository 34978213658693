import { ReactComponent as a } from '../assets/a.svg';
import { ReactComponent as b } from '../assets/b.svg';
import { ReactComponent as c } from '../assets/c.svg';
import { ReactComponent as d } from '../assets/d.svg';
import { ReactComponent as e } from '../assets/e.svg';
import { ReactComponent as f } from '../assets/f.svg';
import { ReactComponent as g } from '../assets/g.svg';
import { ReactComponent as h } from '../assets/h.svg';
import { ReactComponent as i } from '../assets/i.svg';
import { ReactComponent as j } from '../assets/j.svg';
import { ReactComponent as k } from '../assets/k.svg';
import { ReactComponent as l } from '../assets/l.svg';
import { ReactComponent as m } from '../assets/m.svg';
import { ReactComponent as n } from '../assets/n.svg';
import { ReactComponent as o } from '../assets/o.svg';
import { ReactComponent as p } from '../assets/p.svg';
import { ReactComponent as q } from '../assets/q.svg';
import { ReactComponent as r } from '../assets/r.svg';
import { ReactComponent as s } from '../assets/s.svg';
import { ReactComponent as t } from '../assets/t.svg';
import { ReactComponent as u } from '../assets/u.svg';
import { ReactComponent as v } from '../assets/v.svg';
import { ReactComponent as w } from '../assets/w.svg';
import { ReactComponent as x } from '../assets/x.svg';
import { ReactComponent as y } from '../assets/y.svg';
import { ReactComponent as z } from '../assets/z.svg';
import { ReactComponent as comma } from '../assets/comma.svg';
import { ReactComponent as dot } from '../assets/dot.svg';
import { ReactComponent as question_mark } from '../assets/question_mark.svg';
import { ReactComponent as minus } from '../assets/minus.svg';
import { ReactComponent as dash } from '../assets/dash.svg';
import { LetterIdType, SpecCharType } from '../types';
import { FunctionComponent } from 'react';

export const useBanner = () => {
    const letters: Record<
        LetterIdType | SpecCharType,
        FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>
    > = {
        a,
        b,
        c,
        d,
        e,
        f,
        g,
        h,
        i,
        j,
        k,
        l,
        m,
        n,
        o,
        p,
        q,
        r,
        s,
        t,
        u,
        v,
        w,
        x,
        y,
        z,
        comma,
        dot,
        question_mark,
        minus,
        dash,
    };
    return { letters };
};
