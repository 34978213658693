import { Templates } from '../../components';
import { Banner } from '../../components/Banner/Banner1';
import { useAppSelector } from '@/hooks/redux';

export const LabList = () => {
    const {
        newsListState: { data },
    } = useAppSelector((state) => state.NewsSlice);
    return (
        <>
            {data && <Banner {...data[0]} />}
            <Templates />
        </>
    );
};
