import { useNavigate } from 'react-router-dom';
import {
    StyledContainer,
    StyledBanner,
    StyledSubtitle,
    StyledSubtitleContainer,
    StyledMinDescription,
} from './styled1';
import { LetterIdType } from './types';
import { CSSProperties } from 'react';
import { useBanner } from './hooks/useBanner';
import { baseColors } from '@/constants';
import { INews } from '@/types';

type DirectType = 'tl' | 'tr' | 'bl' | 'br';

type PartialNews = Partial<INews>;

interface IDescript {
    index: number;
    text: string;
    position: DirectType;
}

interface IProps extends PartialNews {
    descriptions?: IDescript[];
    title: string;
    cols: number;
    subtitle: string;
}

const position: Record<DirectType, CSSProperties> = {
    tl: {
        position: 'absolute',
        top: '7.41%',
        left: '7.41%',
    },
    tr: {
        position: 'absolute',
        top: '7.41%',
        right: '7.41%',
    },
    bl: {
        position: 'absolute',
        left: '7.41%',
        bottom: '7.41%',
    },
    br: {
        position: 'absolute',
        right: '7.41%',
        bottom: '7.41%',
    },
};

export const Banner = ({
    title,
    cols,
    descriptions,
    subtitle,
    accents,
    readable_title,
    style_container,
    style_title_container,
    style_subtitle_container,
}: IProps) => {
    const { letters } = useBanner();
    const navigate = useNavigate();
    const renderLetters = () => {
        let result = title;
        if (!/^[aA-zZ\s,.?!-–]+$/.test(result)) {
            result = 'defbox';
        }
        const renderData = result
            .toLowerCase()
            .split('')
            .reduce<Array<string | IDescript>>((acc, curr, idx) => {
                if (descriptions) {
                    const descrItem = descriptions.find((i) => i.index === idx);
                    if (descrItem) acc = [...acc, descrItem];
                }
                acc = [...acc, curr];
                return acc;
            }, []);
        if (descriptions) {
            const descrItem = descriptions.find(
                (i) => i.index > renderData.length - 1
            );
            if (descrItem) renderData.push(descrItem);
        }
        return renderData.map((l, idx) => {
            if (typeof l !== 'string') {
                return (
                    <div style={{ position: 'relative' }}>
                        <p
                            style={{
                                ...position[l.position],
                                fontSize: '20px',
                                lineHeight: '24px',
                                textTransform: 'uppercase',
                            }}
                        >
                            {l.text}
                        </p>
                    </div>
                );
            }
            const isAccent = accents && accents.includes(idx);
            const styleAccent: CSSProperties | undefined = isAccent
                ? { color: baseColors.critical() }
                : undefined;
            switch (l) {
                case ',':
                    const Comma = letters['comma'];
                    return <Comma style={styleAccent} key={idx} />;
                case '.':
                    const Dot = letters['dot'];
                    return <Dot style={styleAccent} key={idx} />;
                case '?':
                    const QuestionMark = letters['question_mark'];
                    return <QuestionMark style={styleAccent} key={idx} />;
                case '-':
                    const Minus = letters['minus'];
                    return <Minus style={styleAccent} key={idx} />;
                case '–':
                    const Dash = letters['dash'];
                    return <Dash style={styleAccent} key={idx} />;
                case ' ':
                    return <div></div>;
                default:
                    const Letter = letters[l as LetterIdType];
                    return <Letter style={styleAccent} key={idx} />;
            }
        });
    };
    return (
        <StyledBanner
            $cols={cols}
            onClick={() => navigate('/home/news')}
            $styleString={style_container}
        >
            <StyledContainer $cols={cols} $styleString={style_title_container}>
                {renderLetters()}
            </StyledContainer>
            <StyledSubtitleContainer $styleString={style_subtitle_container}>
                <StyledSubtitle>{subtitle}</StyledSubtitle>
                <StyledMinDescription>{`*${readable_title}`}</StyledMinDescription>
            </StyledSubtitleContainer>
        </StyledBanner>
    );
};
