import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkGemoji from 'remark-gemoji';
import emoji from 'remark-emoji';
import { ArrowLeftIcon, IconButton } from '@/components';
import { baseColors } from '@/constants';
import { useAppSelector } from '@/hooks/redux';

import {
    StyledContainer,
    StyledContent,
    StyledHeader,
    StyledDescription,
    StyledHeaderSeparator,
    StyledTitleContainer,
    StyledTitle,
    StyledSubtitle,
} from './styled';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const News = () => {
    const navigate = useNavigate();
    const {
        newsListState: { data },
    } = useAppSelector((state) => state.NewsSlice);

    return (
        <StyledContainer>
            <StyledHeader>
                <IconButton
                    onClick={() => navigate('/home/labs')}
                    stylePropLabel={`color: ${baseColors.link()}; text-transform: none`}
                    label="Go back"
                    size="S"
                    iconLeft={
                        <ArrowLeftIcon variant="S" fill={baseColors.link()} />
                    }
                />
                <StyledHeaderSeparator />
                <StyledDescription>Last news</StyledDescription>
                <StyledHeaderSeparator />
                <StyledDescription>
                    {data && data.length
                        ? dayjs.utc(data[0].created).format('DD.MM.YYYY')
                        : 'DD.MM.YYYY'}
                </StyledDescription>
            </StyledHeader>
            <StyledContent>
                {data && data.length && (
                    <>
                        <StyledTitleContainer>
                            <StyledTitle>{data[0].readable_title}</StyledTitle>
                            <StyledSubtitle>{data[0].subtitle}</StyledSubtitle>
                        </StyledTitleContainer>
                        <Markdown
                            remarkPlugins={[
                                remarkGfm,
                                remarkBreaks,
                                emoji,
                                remarkGemoji,
                            ]}
                        >
                            {data[0].text}
                        </Markdown>
                    </>
                )}
            </StyledContent>
        </StyledContainer>
    );
};
