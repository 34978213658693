import { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from '@/hooks/usePopper/usePopper';
import { PLACEMENTS } from '@/hooks/usePopper/constants';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { useCombinedRefs } from '@/hooks/useCombinedRef';
import { DropdownMenuProps, IDropdownMenuItem } from '../types';
import {
    StyledDropdownMenu,
    StyledDropdown,
    StyledDropdownContainer,
    StyledSearchContainer,
    StyledSearchField,
} from '../styled';
import { DropdownMenuItem } from './DropdownMenuItem';

export const DropdownMenu = ({
    list: inputList = [],
    onClick,
    onClose,
    toggleOpen,
    renderItem,
    children,
    style,
    styleContainer,
    styleItemsContainer,
    disabled,
    placement,
    offset,
    matchWidth,
    isOpen: inputIsOpen,
    flip = false,
    emptyItem,
    autocomplete,
}: DropdownMenuProps) => {
    const [isOpen, setIsOpen] = useState<boolean | undefined>(inputIsOpen);
    const [searchValue, setSearchValue] = useState('');

    const list = useMemo<IDropdownMenuItem[]>(() => {
        if (!inputList.length) {
            if (emptyItem) {
                return [{ ...emptyItem }];
            }
            return [{ id: 0, title: 'List is empty' }];
        }
        return inputList.filter(
            (i) =>
                i.title
                    ?.toLocaleLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                i.description?.toLowerCase().includes(searchValue.toLowerCase())
        );
    }, [inputList, emptyItem, searchValue]);

    useEffect(() => {
        setIsOpen(inputIsOpen);
    }, [inputIsOpen, setIsOpen]);

    const { referenceRef, popperRef } = usePopper({
        enabled: true,
        placement: placement || PLACEMENTS.bottomLeft,
        flip,
        offset: offset || 4,
        arrowPadding: 0,
        matchWidth: matchWidth || false,
    });

    const dropdownRef = useCombinedRefs<HTMLDivElement | null>(referenceRef);

    useOutsideClick(dropdownRef, (e) => {
        if (onClose) {
            onClose(e);
            setSearchValue('');
        } else {
            setIsOpen(false);
        }
    });

    const onClickHandler = (id: IDropdownMenuItem['id']) => {
        if (onClick) onClick(id);
    };

    const renderOverlay = () => {
        if (isOpen && dropdownRef.current && !disabled) {
            return createPortal(
                <StyledDropdownContainer ref={popperRef} style={styleContainer}>
                    <StyledDropdownMenu style={styleItemsContainer}>
                        {autocomplete && (
                            <StyledSearchContainer>
                                <StyledSearchField
                                    placeholder="Search"
                                    type="text"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    value={searchValue}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        setSearchValue(e.target.value);
                                    }}
                                />
                            </StyledSearchContainer>
                        )}
                        {list.map((li) => {
                            if (!!renderItem) {
                                return renderItem(li);
                            }
                            return (
                                <DropdownMenuItem
                                    {...li}
                                    onClickHandler={onClickHandler}
                                    key={li.id}
                                />
                            );
                        })}
                    </StyledDropdownMenu>
                </StyledDropdownContainer>,
                window.document.body
            );
        } else {
            return null;
        }
    };

    const onFocusHandler = () => {
        if (!disabled) {
            if (!toggleOpen) {
                setIsOpen(true);
            } else {
                toggleOpen();
            }
        }
    };

    return (
        <>
            <StyledDropdown
                style={style}
                ref={dropdownRef}
                onClick={onFocusHandler}
            >
                {children}
            </StyledDropdown>
            {renderOverlay()}
        </>
    );
};
