import styled, { css } from 'styled-components';
import { typography, baseColors } from '@/constants';

const generateStyle = ({ $styleString }: { $styleString?: string | null }) => {
    if ($styleString) {
        return css`
            ${$styleString}
        `;
    }
    return null;
};

export const StyledBanner = styled.div<{
    $cols: number;
    $styleString?: string | null;
}>`
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 8px;
    display: grid;
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 0;
    grid-template-columns: ${({ $cols }) => `${$cols - 1}fr 1fr`};
    ${generateStyle}
`;

export const StyledSubtitleContainer = styled.div<{
    $styleString?: string | null;
}>`
    box-sizing: border-box;
    padding: 2.05%;
    display: flex;
    gap: 8px;
    flex-direction: column;
    ${generateStyle}
`;

export const StyledSubtitle = styled.p`
    ${typography.title.XL};
    text-transform: uppercase;
`;

export const StyledMinDescription = styled.p`
    ${typography.title.M};
    text-transform: uppercase;
    color: ${baseColors.critical()};
`;

export const StyledContainer = styled.div<{
    $cols: number;
    $styleString?: string | null;
}>`
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: ${({ $cols }) => `repeat(${$cols},1fr)`};
    > svg {
        width: 100%;
    }
    ${generateStyle}
`;
